import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentIntent } from '@stripe/stripe-js';
import { Dispatch, SetStateAction, useState } from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';

type Props = {
  clientSecret: string;
  paymentMethodId: string;
  paymentMethodType?: 'card' | 'bank_account';
  loading: boolean;
  onSubmit: (pi?: PaymentIntent) => void;
  setApiMessage: Dispatch<SetStateAction<ApiMessageData | undefined>>;
};

const PaymentButton = ({
  clientSecret,
  paymentMethodId,
  paymentMethodType,
  loading,
  onSubmit,
  setApiMessage,
  ...rest
}: ButtonProps & Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const onClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    if (!clientSecret) {
      setApiMessage({ success: false, status: 400, message: 'Please add or select a payment method.' });
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setConfirmLoading(true);

    try {
      const confirmPayment =
        paymentMethodType === 'card' ? stripe.confirmCardPayment : stripe.confirmUsBankAccountPayment;

      const { paymentIntent, error } = await confirmPayment(clientSecret, {
        payment_method: paymentMethodId,
      });

      if (error) {
        setApiMessage({
          success: false,
          status: 402,
          message: error.message,
        });

        setConfirmLoading(false);
        return;
      }

      setConfirmLoading(false);
      onSubmit(paymentIntent);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Button
      type="button"
      content="Authorize Payment"
      color="blue"
      loading={loading || confirmLoading}
      onClick={onClick}
      {...rest}
    />
  );
};

export default PaymentButton;
