import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form } from 'semantic-ui-react';

import { ChangePasswordInput, useChangePasswordMutation } from 'src/api/auth';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';

type ValidationErrors = {
  current_password?: string;
  new_password?: string;
  confirm_password?: string;
};

const getInitialFormdata = () => ({
  current_password: '',
  new_password: '',
  confirm_password: '',
});

const ChangePasswordForm = () => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useChangePasswordMutation();
  const [formdata, setFormdata] = useState<ChangePasswordInput>(() => getInitialFormdata());
  const [errors, setErrors] = useState<ValidationErrors>({});

  const onChange = useCallback((_, { name, value }) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const validate = useCallback((input: ChangePasswordInput): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (input.current_password === '') {
      validationErrors.current_password = 'Current password is required';
    }

    if (input.new_password === '') {
      validationErrors.new_password = 'New password is required';
    }

    if (input.confirm_password === '') {
      validationErrors.confirm_password = 'Confirm password is required';
    } else if (input.new_password !== input.confirm_password) {
      validationErrors.confirm_password = 'Confirm password does not match New password';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onSubmit = useCallback(async () => {
    if (!isEmpty(validate(formdata))) {
      return;
    }

    setApiMessage(undefined);

    try {
      const { message } = await mutateAsync(formdata);
      setFormdata(getInitialFormdata());
      setApiMessage({ success: true, status: 200, message });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [formdata, mutateAsync, validate]);

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Form.Input
        type="password"
        label="Current Password"
        onChange={onChange}
        name="current_password"
        value={formdata.current_password}
        error={errors.current_password}
      />
      <Form.Input
        type="password"
        label="New Password"
        onChange={onChange}
        name="new_password"
        value={formdata.new_password}
        error={errors.new_password}
      />
      <Form.Input
        type="password"
        label="Confirm Password"
        onChange={onChange}
        name="confirm_password"
        value={formdata.confirm_password}
        error={errors.confirm_password}
      />

      <Button color="blue" loading={isLoading}>
        Change Password
      </Button>
    </Form>
  );
};

export default ChangePasswordForm;
