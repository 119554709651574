import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import SupportModal from './SupportModal';

const FabContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;

  .ui.button {
    margin: 0;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    transform: translateY(0);
    transition: all 150ms ease-in-out;

    &:hover {
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.5);
      transform: translateY(-2px);
    }
  }
`;

const Support = () => {
  return (
    <SupportModal
      trigger={
        <FabContainer>
          <Button type="button" color="blue" icon size="huge" circular>
            <Icon name="help" />
          </Button>
        </FabContainer>
      }
    />
  );
};

export default Support;
