import { ReactElement } from 'react';
import { Card, Icon } from 'semantic-ui-react';

import { BillingPaymentMethod } from 'src/types';
import PaymentMethodBankAccount from './PaymentMethodBankAccount';
import PaymentMethodCard from './PaymentMethodCard';

type Props = {
  extra?: ReactElement;
  onClick?: () => void;
  paymentMethod: BillingPaymentMethod;
  selected?: boolean;
};

const PaymentMethod = ({ extra, onClick, paymentMethod: pm, selected }: Props) => {
  let PaymentMethodDetails: JSX.Element | undefined = undefined;

  switch (pm.type) {
    case 'bank_account':
      if (!pm.bankAccount) break;
      PaymentMethodDetails = <PaymentMethodBankAccount bankAccount={pm.bankAccount} />;
      break;

    case 'card':
      if (!pm.card) break;
      PaymentMethodDetails = <PaymentMethodCard card={pm.card} />;
      break;
  }

  return (
    <Card fluid onClick={onClick}>
      <Card.Content>
        {PaymentMethodDetails}
        {typeof selected !== 'undefined' && (
          <Icon
            name={selected ? 'check' : 'circle outline'}
            color={selected ? 'green' : 'grey'}
            size="big"
            style={{
              position: 'absolute',
              right: '1rem',
              top: '50%',
              transform: 'translateY(-50%)',
              opacity: selected ? undefined : '0.3',
            }}
          />
        )}
      </Card.Content>
      {extra && <Card.Content extra>{extra}</Card.Content>}
    </Card>
  );
};

export default PaymentMethod;
