import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

import { useGetAccountBillingSetupIntentMutation } from 'src/api/billing';
import AddPaymentMethodForm from './AddPaymentMethodForm';
import { AddPaymentMethodButton } from './styles';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

type Props = {
  redirect: string;
};

const AddPaymentMethod = ({ redirect }: Props) => {
  const [clientSecret, setClientSecret] = useState('');
  const { mutateAsync, isLoading } = useGetAccountBillingSetupIntentMutation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) return;

    try {
      mutateAsync().then(data => setClientSecret(data));
    } catch (e: any) {}
  }, [mutateAsync, open]);

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <AddPaymentMethodButton type="button">
          <Icon name="plus" />
          Add Payment Method
        </AddPaymentMethodButton>
      }
    >
      <Modal.Header>Add Payment Method</Modal.Header>
      <Modal.Content>
        {isLoading || clientSecret === '' ? (
          <Button content="Loading..." fluid basic />
        ) : (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <AddPaymentMethodForm redirect={redirect} />
          </Elements>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default AddPaymentMethod;
