import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { BillingPaymentMethod } from '../types';
import { ApiResponse, http } from './http-common';

export type AccountBillingDetailsResponse = ApiResponse & {
  paymentMethods: BillingPaymentMethod[];
};

export type AccountBillingSetupIntentResponse = ApiResponse & {
  clientSecret: string;
};

export const useGetAccountBillingDetailsQuery = () => {
  return useQuery(['billing'], async () => {
    const res = await http.get<AccountBillingDetailsResponse>(`/api/billing`);
    return res.data.paymentMethods;
  });
};

export const useGetAccountBillingSetupIntentMutation = () => {
  return useMutation(async () => {
    const res = await http.get<AccountBillingSetupIntentResponse>(`/api/billing/setup-intent`);
    return res.data.clientSecret;
  });
};

export type RemovePaymentMethodInput = {
  id: string;
};

export const useRemovePaymentMethodMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id }: RemovePaymentMethodInput) => {
      const res = await http.delete<ApiResponse>(`/api/billing/payment-methods/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['billing']);
        queryClient.invalidateQueries(['auth/profile']);
      },
    }
  );
};

export type SetDefaultPaymentMethodInput = {
  id: string;
};

export const useSetDefaultPaymentMethodMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id }: SetDefaultPaymentMethodInput) => {
      const res = await http.post<ApiResponse>(`/api/billing/payment-methods/${id}/make-default`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['billing']);
        queryClient.invalidateQueries(['auth/profile']);
        queryClient.removeQueries(['billing']);
      },
    }
  );
};
