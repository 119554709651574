import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

import theme from 'src/styles/theme';

const LinkDetail = styled.span`
  display: block;
  color: ${theme.gray};
  font-size: 0.8rem;
  line-height: 1rem;
`;

type Props = {
  text: string;
  to: LinkProps['to'];
  detail?: string;
};

const LinkWithDetail = ({ detail, text, to }: Props) => {
  return (
    <>
      <Link to={to}>
        <strong>{text}</strong>
      </Link>
      {detail && <LinkDetail>{detail}</LinkDetail>}
    </>
  );
};

export default LinkWithDetail;
