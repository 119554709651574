import { FC } from 'react';
import styled from 'styled-components';

import theme from 'src/styles/theme';

type Props = {
  crumbs: string[];
};

const Breadcrumb = styled.span`
  color: ${theme.gray};
  padding-right: 1rem;

  &:after {
    content: '/';
    padding-left: 1rem;
    color: black;
  }

  &:last-child {
    color: black;

    &:after {
      content: '';
      padding-left: 0;
    }
  }
`;

const Breadcrumbs: FC<Props> = ({ crumbs }) => {
  return (
    <span>
      {crumbs.map((crumb, index) => (
        <Breadcrumb key={index}>{crumb}</Breadcrumb>
      ))}
    </span>
  );
};

export default Breadcrumbs;
