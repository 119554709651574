import _capitalize from 'lodash/capitalize';
import { Card, Icon } from 'semantic-ui-react';

import { Row } from 'src/styles';
import { BillingBankAccount } from 'src/types';

type Props = {
  bankAccount: BillingBankAccount;
};

const PaymentMethodBankAccount = ({ bankAccount }: Props) => {
  return (
    <Row style={{ alignItems: 'center' }}>
      <div>
        <Icon name="building" color="blue" size="big" style={{ fontSize: '2.5em', marginRight: '1rem' }} />
      </div>
      <div>
        <Card.Header>
          {bankAccount.bankName} **** {bankAccount.last4}
        </Card.Header>
        <Card.Meta>{_capitalize(bankAccount.accountType)}</Card.Meta>
      </div>
    </Row>
  );
};

export default PaymentMethodBankAccount;
