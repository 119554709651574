import { useMutation } from 'react-query';

import { ApiResponse, http } from './http-common';

export type SendSupportRequestInput = {
  message: string;
};

export const useSendSupportRequest = () => {
  return useMutation(async (params: SendSupportRequestInput) => {
    const res = await http.post<ApiResponse>(`/api/slack/support-request`, params);
    return res.data;
  });
};
