import { Card, Icon, SemanticICONS } from 'semantic-ui-react';

import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import { BillingCard } from 'src/types';

type Props = {
  card: BillingCard;
};

const PaymentMethodCard = ({ card }: Props) => {
  let name: SemanticICONS | undefined = undefined;
  switch (card.brand) {
    case 'amex':
    case 'discover':
    case 'mastercard':
    case 'visa':
      name = `cc ${card.brand}`;
      break;

    default:
      name = 'credit card';
  }

  return (
    <Row style={{ alignItems: 'center' }}>
      <div>
        <Icon name={name} color="blue" size="big" style={{ fontSize: '2.5em', marginRight: '1rem' }} />
      </div>
      <div>
        <Card.Header style={{ color: theme.black }}>
          {card.brand.toUpperCase()} **** {card.last4}
        </Card.Header>
        <Card.Meta>
          Exp {card.expMonth}/{card.expYear}
        </Card.Meta>
      </div>
    </Row>
  );
};

export default PaymentMethodCard;
