import { List, Message } from 'semantic-ui-react';

import { ApiMessageData } from 'src/api/http-common';

type ApiMessageProps = {
  data?: ApiMessageData;
};

const ApiMessage = ({ data }: ApiMessageProps) => {
  if (!data || !data.message) return null;

  if (data.success) {
    return (
      <Message success visible>
        <Message.Header>🙌 Hooray!</Message.Header>
        <Message.Content>{data.message}</Message.Content>
      </Message>
    );
  }

  return (
    <Message error visible>
      <Message.Header>🚨 Error {data.status}</Message.Header>
      <Message.Content>
        {typeof data.message === 'string' ? (
          data.message
        ) : (
          <List bulleted style={{ marginTop: '0.5rem' }}>
            {data.message.map((m, i) => (
              <List.Item key={i}>{m}</List.Item>
            ))}
          </List>
        )}
      </Message.Content>
    </Message>
  );
};

export default ApiMessage;
